import React from 'react';
import { useNavigate } from 'react-router-dom';

const PagoFallido: React.FC = () => {
  const navigate = useNavigate();

  const handleRetry = () => {
    navigate('/detalles-contacto'); // Redirigir al formulario para intentar de nuevo
  };

  return (
    <div className="container mx-auto p-6 bg-white shadow-md rounded">
      <h1 className="text-3xl font-bold text-center mb-6 text-red-600">Pago Fallido</h1>
      <p className="text-lg text-center mb-8">
        Lo sentimos, el pago no se pudo procesar. Por favor, intenta nuevamente.
      </p>
      <div className="flex justify-center">
        <button
          onClick={handleRetry}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Intentar de nuevo
        </button>
      </div>
    </div>
  );
};

export default PagoFallido;
