import React, { useState, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { FaCreditCard } from 'react-icons/fa';
import { LanguageContext } from '../context/LanguageContext';

// Cargar la clave de API desde variables de entorno y verificar su valor
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY!);
console.log("Stripe API Key:", import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

interface StripeCheckoutProps {
  total: number; // Total en dólares
}

const StripeCheckout: React.FC<StripeCheckoutProps> = ({ total }) => {
  const { language } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);

  const handleCheckout = async () => {
    setLoading(true);
    const stripe = await stripePromise;

    if (!stripe) {
      console.error("Stripe no se inicializó correctamente.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${window.location.origin}/api/create-checkout-session`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          items: [
            {
              title: 'Reserva de Camioneta',
              unit_price: total,
              quantity: 1,
            },
          ],
        }),
      });

      if (!response.ok) {
        console.error("Error en la respuesta de la API:", response.statusText);
        setLoading(false);
        return;
      }

      const { id } = await response.json();
      if (!id) {
        console.error("Error: No se recibió un ID de sesión.");
        setLoading(false);
        return;
      }

      // Redirigir al usuario a Stripe usando el ID de la sesión
      const { error } = await stripe.redirectToCheckout({ sessionId: id });

      if (error) {
        console.error('Error en el redireccionamiento:', error.message);
      }
    } catch (error) {
      console.error('Error al crear la sesión de pago:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
      <div className="mb-6 text-center">
        <p className="text-2xl font-semibold">
          {language === 'es' ? 'Pago con Tarjeta' : 'Card Payment'}
        </p>
        <p className="text-xl font-bold mt-2">
          {language === 'es' ? `Total a pagar: $${total} USD` : `Total to pay: $${total} USD`}
        </p>
      </div>

      <button
        onClick={handleCheckout}
        disabled={loading}
        className="flex items-center justify-center w-full px-6 py-3 text-white bg-gradient-to-r from-yellow-400 to-black hover:from-yellow-300 hover:to-gray-800 focus:outline-none focus:ring-4 focus:ring-yellow-200 rounded-lg transition-all duration-300"
      >
        <FaCreditCard className="mr-2 text-white" />
        {loading
          ? language === 'es'
            ? 'Redirigiendo...'
            : 'Redirecting...'
          : language === 'es'
          ? 'Pagar con Stripe'
          : 'Pay with Stripe'}
      </button>
    </div>
  );
};

export default StripeCheckout;
