import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import LogoFooter from '../assets/Logo_Footer.png';
import { FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa'; // Importamos los iconos

const Footer: React.FC = () => {
  const { language } = useContext(LanguageContext);

  const formUrl =
    language === 'es'
      ? 'https://api.leadconnectorhq.com/widget/form/2QOP6YRLGZnEsCCuGS2U'
      : 'https://api.leadconnectorhq.com/widget/form/4sVzNjfSdIZrXfFbSl7n';

  return (
    <footer className="bg-gray-900 text-gray-300 py-10">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-10">
        <div>
          <h4 className="text-lg font-bold text-white mb-4">
            {language === 'es' ? 'Dirección' : 'Address'}
          </h4>
          <p className="text-sm">
            {language === 'es'
              ? 'DIRECCION: CALLE SALVADOR DALLI SN COL GUAYMITAS CP 23407'
              : 'ADDRESS: CALLE SALVADOR DALLI SN COL GUAYMITAS CP 23407'}
          </p>
        </div>

        <div className="flex flex-col items-center">
          <img src={LogoFooter} alt="Logo Footer" className="h-20 mb-4" />

          {/* Sección de iconos de redes sociales */}
          <div className="flex space-x-4">
            <a href="https://www.facebook.com/profile.php?id=61554584787768&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <FaFacebook className="text-gray-300 hover:text-white text-2xl" />
            </a>
            <a href="https://www.instagram.com/cabobaja_transport?igsh=YjhoeG93dDYydXI5" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <FaInstagram className="text-gray-300 hover:text-white text-2xl" />
            </a>
            <a href="https://www.tiktok.com/@cabo.baja.trans?_t=8rE9dE3z0EC&_r=1" target="_blank" rel="noopener noreferrer" aria-label="TikTok">
              <FaTiktok className="text-gray-300 hover:text-white text-2xl" />
            </a>
          </div>
        </div>

        <div>
          <h4 className="text-lg font-bold text-white mb-4">
            {language === 'es' ? 'Contacto' : 'Contact'}
          </h4>
          <p className="text-sm">
            {language === 'es' ? 'TEL OFICINA:' : 'OFFICE PHONE:'} 62 43 18 32 31
          </p>
          <p className="mt-4 text-sm">E-mail: holacabobaja@gmail.com</p>
        </div>
      </div>

      <div className="mt-10 container mx-auto grid grid-cols-1 md:grid-cols-3 gap-10">
        <div className="md:col-span-3">
          <iframe
            title={language === 'es' ? 'Formulario de contacto' : 'Contact Form'}
            src={formUrl}
            style={{ width: '100%', height: '550px', border: 'none', overflow: 'hidden' }}
            scrolling="no"
          ></iframe>
        </div>
      </div>

      <div className="mt-10 text-center text-sm text-gray-500">
        © 2024 {language === 'es' ? 'Cabo Baja Trans' : 'Cabo Baja Trans'}.{' '}
        {language === 'es' ? 'Todos los derechos reservados.' : 'All rights reserved.'}
        <br />
        {language === 'es' ? 'Desarrollado por BANANA SOLUCIONES.' : 'Developed by BANANA SOLUCIONES.'}
      </div>
    </footer>
  );
};

export default Footer;
